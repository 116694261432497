import React from "react";

import whats from "../../assets/images/whatsappwhite.png";
import close from "../../assets/images/close.png";

import "./styles.css";

const Modal: React.FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
}> = ({ open, setOpen }) => {
  const openWhats = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=556332142818&text=Ol%C3%A1%20Niceplanet%20Geotecnologia%2C%20eu%20gostaria%20de%20falar%20com%20um%20de%20seus%20especialistas%20em%20gest%C3%A3o%20socioambiental%20para%20frigor%C3%ADficos.%20"
    );
  };

  if (!open) return null;
  return (
    <>
      <div className="modal"></div>
      <div className="modalContainer">
        <button className="modalClose" onClick={() => setOpen(!open)}>
          <img src={close} alt="fechar" />
        </button>
        <h3 className="modalTitle">Parabéns</h3>
        <h4 className="modalDescription">
          Ao baixar o nosso e-book,{" "}
          <span>você ganhou uma consultoria gratuita</span> com nossos
          especialistas para avaliar a gestão socioambiental do seu frigorífico.
        </h4>
        <p>Clique abaixo para avaliar a sua consultoria.</p>
        <button className="modalButton" onClick={openWhats}>
          Agendar agora!
          <img src={whats} alt="agendar agora" />
        </button>
      </div>
    </>
  );
};

export default Modal;

import React, { useRef, useState } from "react";

import "./App.css";

import mockup from "./assets/images/mockup-ebook.png";
import logonice from "./assets/images/logonice.png";
import InputMask from "react-input-mask";
import instagram from "./assets/images/instagram.png";
import linkedin from "./assets/images/linkedin.png";
import site from "./assets/images/site.png";
import whatsapp from "./assets/images/whatsapp.png";

import Modal from "./components/Modal/Modal";

const App = () => {
  const form = useRef(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (form.current)
      fetch(
        "https://events.sendpulse.com/events/id/f1feb357e8374453cda1fb839ca99111/8097520",
        {
          method: "POST",
          body: new FormData(form.current),
          mode: "no-cors",
        }
      );
    setModalOpen(true);
  };

  const openSocial = (social: string) => {
    switch (social) {
      case "linkedin":
        window.open(
          "https://www.linkedin.com/company/niceplanet/?originalSubdomain=br"
        );
        break;
      case "instagram":
        window.open("https://www.instagram.com/niceplanetgeotecnologia/");
        break;
      case "site":
        window.open("https://niceplanet.com.br/");
        break;
      default:
        return;
    }
  };

  return (
    <div className={`${!modalOpen ? "App" : "App filtered"}`}>
      <div className="container">
        <div className="ebook">
          <img src={mockup} alt="" />
        </div>
        <div className="content">
          <div className="description">
            <img src={logonice} alt="logo nice" />
            <h3>E-BOOK GRATUÍTO</h3>
            <p>
              O guia definitivo para garantir ao seu frigorífico 100% de
              conformidade com o Tac da Carne, os principais acordos comerciais
              e as políticas de compra dos curtumes e do mercado.
            </p>
          </div>
          <form ref={form} className="contact" onSubmit={onSubmit}>
            <input type="text" required placeholder="Seu nome" name="name" />
            <InputMask
              placeholder="Whatsapp"
              mask="(99)99999-9999"
              name="phone"
            />
            <input type="email" required placeholder="E-mail" name="email" />
            <button type="submit">Baixar agora</button>
          </form>
        </div>
      </div>

      <footer>
        <div className="separator"></div>
        <div className="footerLinks">
          <div className="social">
            <button onClick={() => openSocial("linkedin")}>
              <img src={linkedin} alt="linkedin" />
            </button>
            <button onClick={() => openSocial("instagram")}>
              <img src={instagram} alt="instagram" />
            </button>
            <button onClick={() => openSocial("site")}>
              <img src={site} alt="site" />
            </button>
          </div>
          <div className="footerContact">
            <button>
              Fale com nossos especialistas
              <img src={whatsapp} alt="whatsapp" />
            </button>
          </div>
        </div>
      </footer>
      <Modal open={modalOpen} setOpen={setModalOpen} />
    </div>
  );
};

export default App;
